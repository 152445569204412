import {useAppContext} from '../../../app-context.js';
import {useRequest} from '../../../common/hooks';
import {
  loadDataReq,
  addAttachmentReq,
  deleteAttachmentReq
} from '../../../common/api/api.js';

export const useProcessAttachmentList = () => {
  const {user} = useAppContext();

  const {request} = useRequest();

  const processAttachmentList = async ({messageTemplateId, attachmentList}) => {
    for (const item of attachmentList) {
      if (!item.id) {
        const link = await request(
          loadDataReq({
            file: item.file,
            category: 'templates'
          })
        );

        if (link) {
          await request(
            addAttachmentReq(user.id, messageTemplateId, {
              link,
              title: item.title,
              type: item.type
            })
          );
        }
      } else if (item.isRemovable) {
        await request(deleteAttachmentReq(user.id, messageTemplateId, item.id));
      }
    }

    return true;
  };

  return processAttachmentList;
};
