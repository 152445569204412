import React from 'react';
import {t} from 'ttag';

import Grid from 'components/grid/grid.jsx';
import Product from '../product/product.jsx';
import {useAppContext} from 'app-context.js';
import getBitrix24TopLevelDomain from '../get-bitrix24-top-level-domain.js';

const WhatcrmProductList = () => {
  const {user, lang} = useAppContext();

  const amocrmTopLevelDomain = lang === 'ru' ? 'ru' : 'com';
  const bitrix24TopLevelDomain = getBitrix24TopLevelDomain(lang);

  const isKommo =
    user.domain.includes('amocrm') ||
    user.domain.includes('kommo') ||
    user.crm === 'AMO' ||
    user.crm == 'AVITO' ||
    user.crm === 'TELEGRAM';

  const isBitrix24 =
    user.domain.includes('bitrix24') ||
    user.crm === 'B24' ||
    user.crm === 'TELPHGRAM';

  const productList = [
    {
      description_short: 'Whatcrm Web',
      description: t`Messaging using multiple WhatsApp and Telegram accounts.`,
      image: '/static/img/products/whatcrm-web.png',
      link: {
        href: process.env.REACT_APP_WEB,
        text: t`Open`
      }
    },
    {
      description_short: t`WhatsApp for Kommo`,
      description: t`Integration via WhatsApp Web API. Recommendations for stable operation: the phone is always online, on charge and with a good internet connection.`,
      image: '/static/img/products/whatcrm-whatsapp.png',
      link: {
        href: `https://www.amocrm.${amocrmTopLevelDomain}/oauth/?mode=popup&client_id=2c45cb68-3a03-4bad-a531-b2c83bda6b08`,
        text: t`Install`
      },
      skip: isBitrix24
    },
    {
      description_short: t`Telegram for Kommo`,
      description: t`Send and receive messages to your personal Telegram phone number. Dialogues and messages are unlimited.`,
      image: '/static/img/products/whatcrm-telegram.png',
      link: {
        href: `https://www.amocrm.${amocrmTopLevelDomain}/oauth/?state=state&mode=popup&client_id=12e7ab9a-c237-4a24-b70a-3b63a291216e`,
        text: t`Install`
      },
      skip: isBitrix24
    },
    {
      description_short: t`Avito for Kommo`,
      description: t`The integration allows customers to automate their work with Avito and communicate with customers directly from Kommo.`,
      image: '/static/img/products/whatcrm-avito.png',
      link: {
        href:
          lang === 'ru'
            ? 'https://avitoamo.whatcrm.net/v1/authorize'
            : 'https://avitoamo.whatcrm.net/v1/authorize_com',
        text: t`Install`
      },
      skip: isBitrix24 || user.crm == 'AVITO'
    },
    {
      description_short: t`WhatsApp for Bitrix24`,
      description: t`Integration via WhatsApp Web API. Recommendations for stable operation: the phone is always online, on charge and with a good internet connection.`,
      image: '/static/img/products/whatcrm-whatsapp.png',
      link: {
        href: isBitrix24
          ? `https://${user.domain}/market/detail/sipteco.whatsapp_dlya_bitriks24_wapp_im/`
          : `https://www.bitrix24.${bitrix24TopLevelDomain}/apps/app/sipteco.whatsapp_dlya_bitriks24_wapp_im/`,
        text: t`Install`
      },
      skip: isKommo
    },
    {
      description_short: t`Telegram for Bitrix24`,
      description: t`Send and receive messages to your personal Telegram phone number. Dialogues and messages are unlimited.`,
      image: '/static/img/products/whatcrm-telegram.png',
      link: {
        href: isBitrix24
          ? `https://${user.domain}/market/detail/telphin.telegram_dlya_bitriks24_whatcrm/`
          : `https://www.bitrix24.${bitrix24TopLevelDomain}/apps/app/telphin.telegram_dlya_bitriks24_whatcrm/`,
        text: t`Install`
      },
      skip: isKommo
    },
    {
      description_short: t`WhatsApp Checker for Bitrix24`,
      description: t`Verify phone number from entities (lead, contact, deal and company) in WhatsApp. Use with Bitrix24 business processes.`,
      image: '/static/img/products/whatcrm-checker.png',
      link: {
        href: `https://www.bitrix24.ru/apps/app/telphin.whatsapp_checker_ot_whatcrm/`,
        text: t`Install`
      },
      skip:
        (user.domain.includes('bitrix24') && user.crm == 'CHECKER') ||
        bitrix24TopLevelDomain != 'ru' ||
        isKommo
    },
    {
      description_short: t`WhatsApp for monday`,
      description: t`Communicate effectively with customers via WhatsApp and integrate this communication with monday.`,
      image: '/static/img/products/whatcrm-whatsapp.png',
      link: {
        href: 'https://auth.monday.com/oauth2/authorize?client_id=7ca9d4564e2f61767840e2c08ca36a43&response_type=install',
        text: t`Install`
      },
      skip: user.crm == 'MONDAY' || isKommo
    }
  ].filter(item => !item.skip);

  return (
    <div>
      <Grid>
        {productList.map((item, i) => (
          <Product key={i} product={item} />
        ))}
      </Grid>
    </div>
  );
};

export default WhatcrmProductList;
