import React from 'react';
import {t} from 'ttag';

import Wrapper from '../../wrapper/wrapper';
import Advice from '../../advice/advice';
import Link from '../../link/link';
import {useAppContext} from '../../../app-context';
import {useConnectionListContext} from '../connection-list-context';

const Advices = () => {
  const {lang} = useAppContext();
  const {client, isConnector} = useConnectionListContext();

  const isAdvicesShown = !client.isCustomer && !isConnector;

  return (
    <>
      {isAdvicesShown && (
        <Wrapper>
          {client.crm == 'PIPEDRIVE' && lang != 'ru' && (
            <>
              <Advice>
                <Link
                  href="https://whatcrm.gitbook.io/whatcrm-docs/v/english/integrations-products/pipedrive"
                  target="_blank"
                >
                  {t`Service setup guide`}
                </Link>
              </Advice>

              <Advice>
                <Link
                  href="https://whatcrm.gitbook.io/whatcrm-docs/v/english/useful-articles/important-recommendations-when-using-whatcrm"
                  target="_blank"
                >
                  {t`Important recommendations for working with the service`}
                </Link>
              </Advice>

              <Advice>
                <Link
                  href="https://whatcrm.gitbook.io/whatcrm-docs/v/english/integrations-products/pipedrive/automated-whatsapp-messaging-with-pipedrive-triggers"
                  target="_blank"
                >
                  {t`How to set up automatic messages`}
                </Link>
              </Advice>
            </>
          )}

          {['MONDAY', 'TGMONDAY'].includes(client.crm) && (
            <Advice>
              <Link
                href={
                  client.crm == 'MONDAY'
                    ? 'https://whatcrm.gitbook.io/whatcrm-docs/v/english/integrations-products/monday.com/installing-the-widget-and-connecting-the-number'
                    : 'https://whatcrm.gitbook.io/whatcrm-docs/v/english/integrations-products/monday.com/telegram'
                }
                target="_blank"
              >
                {t`Service setup guide`}
              </Link>
            </Advice>
          )}

          {['AMO', 'B24', 'LK', 'MONDAY'].includes(client.crm) && (
            <>
              <Advice>
                <Link
                  href={
                    lang == 'ru'
                      ? 'https://whatcrm.gitbook.io/whatcrm-faq/poleznye-stati/whatsapp-rekomendacii'
                      : 'https://whatcrm.gitbook.io/whatcrm-docs/v/english/useful-articles/important-recommendations-when-using-whatcrm'
                  }
                  target="_blank"
                >
                  {t`Important recommendations for working with the service`}
                </Link>
              </Advice>

              {lang == 'ru' && (
                <Advice>
                  <Link
                    href="https://whatcrm.gitbook.io/whatcrm-faq/poleznye-stati/vypolnite-eti-rekomendacii-dlya-stabilnoi-raboty-whatsapp"
                    target="_blank"
                  >
                    {'Рекомендации для стабильной работы WhatsApp'}
                  </Link>
                </Advice>
              )}
            </>
          )}

          {['TELEGRAM', 'TELPHGRAM', 'LK', 'TGMONDAY'].includes(client.crm) && (
            <Advice>
              <Link
                href={
                  lang == 'ru'
                    ? 'https://whatcrm.gitbook.io/whatcrm-docs/v/russian/poleznye-stati/telegram/limity-telegram'
                    : 'https://whatcrm.gitbook.io/whatcrm-docs/v/english/useful-articles/telegram-restrictions'
                }
                target="_blank"
              >
                {t`Telegram restrictions`}
              </Link>
            </Advice>
          )}

          {['MONDAY', 'TGMONDAY'].includes(client.crm) && (
            <Advice>
              <Link href="https://www.youtube.com/@whatcrm" target="_blank">
                {t`Video tutorials`}
              </Link>
            </Advice>
          )}
        </Wrapper>
      )}
    </>
  );
};

export default Advices;
