import React from 'react';
import {t} from 'ttag';
import Grid from 'components/grid/grid.jsx';
import Product from '../product/product.jsx';
import {useAppContext} from 'app-context.js';
import getBitrix24TopLevelDomain from '../get-bitrix24-top-level-domain.js';

const TelphinProductList = () => {
  const {user, lang} = useAppContext();

  const amocrmTopLevelDomain = lang === 'ru' ? 'ru' : 'com';
  const bitrix24TopLevelDomain = getBitrix24TopLevelDomain(lang);

  const isKommo =
    user.domain.includes('amocrm') ||
    user.domain.includes('kommo') ||
    user.crm === 'AMO' ||
    user.crm == 'AVITO' ||
    user.crm === 'TELEGRAM';

  const isBitrix24 = user.crm === 'B24' || user.crm === 'TELPHGRAM';

  const productList = [
    {
      description_short: t`Integration with the Telphin.Office ATS`,
      description: `${t`Integration of Telphin.Office ATS and`} ${t`Kommo`} ${t`will build a competent work with calls.`}`,
      image:
        lang == 'ru'
          ? '/static/img/products/telphin.svg'
          : '/static/img/products/telphin_en.svg',
      link: {
        href: `https://www.amocrm.${amocrmTopLevelDomain}/oauth/?state=state&mode=popup&client_id=78fe1083-e73a-4052-bf06-ca73e109bbb9`,
        text: t`Install`
      },
      skip: isBitrix24
    },
    {
      description_short: t`Integration with the Telphin.Office ATS`,
      description: t`Integration of Telphin.Office ATS and Bitrix24 will build a competent work with calls.`,
      image:
        lang == 'ru'
          ? '/static/img/products/telphin.svg'
          : '/static/img/products/telphin_en.svg',
      link: {
        href: `https://www.bitrix24.${bitrix24TopLevelDomain}/apps/app/telphin.integration/`,
        text: t`Install`
      },
      skip: isKommo
    },
    {
      description_short: t`Telme — always on line`,
      description: t`Receive and make calls from Telegram to any landline or mobile number. Save on communication. Forget about roaming, extra applications and extra SIM cards.`,
      image: '/static/img/products/telme.svg',
      link: {href: `https://t.me/Telmebot`, text: t`Connect`}
    }
  ].filter(item => !item.skip);

  return (
    <div>
      <Grid>
        {productList.map((item, i) => (
          <Product key={i} product={item} />
        ))}
      </Grid>
    </div>
  );
};

export default TelphinProductList;
