import React from 'react';
import {useField} from 'formik';
import _ from 'lodash';
import {t} from 'ttag';

import {MultiSelect} from '../../../../../../components/form/form.jsx';
import * as Title from '../../../../../../components/title/title.jsx';
import {useHomeContext} from '../../../../home-context.js';

const getValue = (uniqConnectionList, numberList) => {
  if (!numberList.length) return JSON.stringify(['-1']);
  else if (
    numberList.length < uniqConnectionList.filter(item => item.phone).length
  )
    return JSON.stringify(numberList);

  return '';
};

const getAvailableConnectionList = (connectionList, value) => {
  if (!value) return [];

  return JSON.parse(value).filter(item =>
    connectionList.find(connection => connection.phone == item)
  );
};

const AccessInstances = () => {
  const {connectionList} = useHomeContext();
  const [field, , helpers] = useField('access_instances');

  const uniqConnectionList = _.uniqBy(connectionList, 'phone');

  const handleChange = temp => {
    const numberList = temp.reduce((acc, item) => {
      if (item.isChecked) acc.push(item.value);
      return acc;
    }, []);

    const value = getValue(uniqConnectionList, numberList);
    helpers.setValue(value);
  };

  const availableConnectionList = getAvailableConnectionList(
    connectionList,
    field.value
  );

  const options = uniqConnectionList
    .filter(item => item.phone)
    .map(item => {
      const isChecked = availableConnectionList.length
        ? availableConnectionList.includes(item.phone)
        : !field.value.length;

      const labels = connectionList.reduce((acc, connection) => {
        if (connection.phone == item.phone)
          acc.push(connection.label || `${t`Connection`} ${connection.id}`);

        return acc;
      }, []);

      return {
        comment: labels.join(', '),
        isChecked,
        label: `+${item.phone}`,
        value: item.phone
      };
    });

  return (
    <>
      <Title.H3 style={{marginBottom: 8}}>{t`Available connections`}</Title.H3>

      <MultiSelect
        options={options}
        placeholder={t`Available connections`}
        isSearchAvailable
        onChange={handleChange}
      />
    </>
  );
};

export default AccessInstances;
