import React from 'react';
import PropTypes from 'prop-types';
import {t} from 'ttag';

import Background from '../../../../../components/background/background.jsx';
import Text from '../../../../../components/text/text.jsx';
import Wrapper from '../../../../../components/wrapper/wrapper.jsx';
import {useAppContext} from '../../../../../app-context.js';
import {useStaffContext} from '../staff-context.js';

import Creating from './creating/creating.jsx';
import Searching from './searching/searching.jsx';

const Actions = ({setVisibleEmployeeList, getEmployeeList}) => {
  const {user} = useAppContext();
  const {employeeList} = useStaffContext();

  return (
    <>
      {user.crm != 'LK' && (
        <Background style={{marginBottom: 16}}>
          <Text color="dark">
            {t`You can add employees in the settings of your CRM.`}
          </Text>
        </Background>
      )}

      <Wrapper
        alignItems="center"
        gap={16}
        style={{marginBottom: 16}}
      >
        <Creating
          setVisibleEmployeeList={setVisibleEmployeeList}
          getEmployeeList={getEmployeeList}
        />

        {employeeList?.length > 4 && <Searching />}
      </Wrapper>
    </>
  );
};

Actions.propTypes = {
  setVisibleEmployeeList: PropTypes.func,
  getEmployeeList: PropTypes.func
};

Actions.defaultProps = {
  setVisibleEmployeeList: () => {},
  getEmployeeList: () => {}
};

export default Actions;
