import React, {useState} from 'react';
import {t} from 'ttag';
import {parsePhoneNumber} from 'libphonenumber-js';
import moment from 'moment';
import PropTypes from 'prop-types';
import update from 'immutability-helper';

import {TableCell} from '../../../../components/table/table.jsx';
import Button from '../../../../components/button/button';
import Confirmation from '../../../../components/confirmation/confirmation.jsx';
import Icons from '../../../../components/icons/icons.jsx';
import Keyword from 'components/keyword/keyword';
import {useAppContext} from '../../../../app-context.js';
import {useRequest} from '../../../../common/hooks';
import {deleteDeniedReq} from '../../../../common/api/api.js';

const Denied = ({denied, denyList, setDenyList}) => {
  const {user} = useAppContext();
  const [isOpen, setIsOpen] = useState(false);
  const {request} = useRequest();

  const deleteDenied = async () => {
    const index = denyList.findIndex(item => item.id === denied.id);

    const res = await request(deleteDeniedReq(user.id, denied.id));
    if (!Object.keys(res).length) return true;

    setDenyList(prevValue =>
      update(prevValue, {
        $splice: [[index, 1]]
      })
    );

    setIsOpen(false);
    return true;
  };

  let international;

  try {
    const normalized = parseInt(denied.phone);
    const parsed = parsePhoneNumber(`+${normalized}`);
    international = parsed.formatInternational();
  } catch {
    //
  }

  return (
    <>
      <TableCell>{moment.unix(denied.date_add).format('L')}</TableCell>

      <TableCell>
        {international ? (
          <Keyword keyword={denied.phone}>{international}</Keyword>
        ) : (
          `@${denied.phone}`
        )}
      </TableCell>

      <TableCell isIcon>
        <Button color="transparent" onClick={() => setIsOpen(true)}>
          <Icons.Trash />
        </Button>

        {isOpen && (
          <Confirmation
            title={t`Are you sure you want to delete this contact from the deny list?`}
            onClick={res => (res ? deleteDenied() : setIsOpen(false))}
          />
        )}
      </TableCell>
    </>
  );
};

Denied.propTypes = {
  denied: PropTypes.shape({
    date_add: PropTypes.number,
    id: PropTypes.number,
    integration_id: PropTypes.number,
    phone: PropTypes.string
  }),
  denyList: PropTypes.array,
  setDenyList: PropTypes.func
};

Denied.defaultProps = {
  denied: {},
  denyList: [],
  setDenyList: () => {}
};

export default Denied;
