import React from 'react';
import {Formik, Form} from 'formik';
import PropTypes from 'prop-types';
import {t} from 'ttag';
import * as yup from 'yup';

import * as Title from '../../../../../components/title/title.jsx';
import Button, {SaveButton} from '../../../../../components/button/button';
import Line from '../../../../../components/line/line.jsx';
import Popup from '../../../../../components/popup/popup.jsx';
import Wrapper from '../../../../../components/wrapper/wrapper.jsx';
import {useAppContext} from 'app-context.js';

import AccessInstances from './access-instances/access-instances.jsx';
import Email from './email/email.jsx';
import Fullname from './fullname/fullname.jsx';
import Phone from './phone/phone.jsx';

const Editor = ({initialValues, onClose, onSubmit, title}) => {
  const {user} = useAppContext();

  const validationSchema = yup.object().shape({
    access_instances: yup.string(),
    email: yup.string().email().required(),
    fullname: yup.string().trim().required(),
    phone: yup.string()
  });

  return (
    <Popup onClick={onClose}>
      <Title.H2 style={{marginBottom: 16}}>{title}</Title.H2>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form>
          {user.crm == 'LK' && (
            <>
              <Fullname />
              <Email />
              <Phone />
            </>
          )}

          <AccessInstances />
          <Line />

          <Wrapper alignItems="center">
            <SaveButton>{t`Save`}</SaveButton>

            <Button color="white" onClick={onClose}>
              {t`Cancel`}
            </Button>
          </Wrapper>
        </Form>
      </Formik>
    </Popup>
  );
};

Editor.propTypes = {
  initialValues: PropTypes.shape({
    access_instances: PropTypes.string,
    email: PropTypes.string,
    fullname: PropTypes.string,
    phone: PropTypes.string
  }),
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string
};

Editor.defaultProps = {
  initialValues: {access_instances: '', email: '', fullname: '', phone: ''},
  onClose: () => {},
  onSubmit: () => {},
  title: ''
};

export default Editor;
