export const fetchBoards = domain => ({params: {domain}, path: 'boards'});

export const fetchGroups = (domain, board_id) => ({
  params: {board_id, domain},
  path: 'groups'
});

export const fetchShortCode = domain => ({params: {domain}, path: 'shortCode'});

export const getConnectionConfigReq = ({domain, phone}) => ({
  path: `connection`,
  params: {domain, phone}
});

export const getManagerListReq = domain => ({
  path: `managers`,
  params: {domain}
});

export const putShortCode = (domain, short_code) => ({
  data: {domain, short_code},
  method: 'put',
  path: 'shortCode'
});

export const updateConnectionConfigReq = ({domain, phone, data}) => ({
  path: `connection`,
  params: {domain, phone},
  method: 'put',
  data
});

export const updateEmployeeListReq = domain => ({
  path: `managers/refresh`,
  params: {domain}
});
