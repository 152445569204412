import update from 'immutability-helper';
import {t} from 'ttag';

import {useAppContext} from '../../app-context.js';
import {useConnectionListContext} from './connection-list-context.js';
import {useRequest} from '../../common/hooks';
import {getConnectionCondition} from '../../common/actions.js';
import {createConnectionReq} from '../../common/api/api.js';

const useCreateConnection = () => {
  const {setNotificationList} = useAppContext();

  const {
    client,
    setConnectionList,
    setOpenConnectionId,
    setConnectionRequiringPreset,
    onCreate
  } = useConnectionListContext();

  const {request} = useRequest();

  const createConnection = async ({label, version = 'whatcrm'}) => {
    const res = await request(
      createConnectionReq(client.crm, client.domain, version, label)
    );

    if (Object.keys(res).length) {
      setConnectionList(prevValue =>
        update(prevValue, {
          $push: [{...res, ...getConnectionCondition(res)}]
        })
      );

      onCreate(res);

      if (client.isCustomer) {
        setNotificationList(prevValue =>
          update(prevValue, {
            $push: [
              {title: `${t`Connection`} ${res.id} ${t`has been created.`}`}
            ]
          })
        );

        return;
      }

      if (client.crm === 'AMO' || client.crm === 'TELEGRAM') {
        setConnectionRequiringPreset(res.id);
      }

      setOpenConnectionId(res.id);
    }
  };

  return createConnection;
};

export default useCreateConnection;
