import React, {useState, useEffect} from 'react';
import {t} from 'ttag';
import update from 'immutability-helper';

import PhoneCodeEditor, {
  PhoneCodeEditorValues
} from '../components/phone-code-editor/phone-code-editor';
import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import {fetchShortCode, putShortCode} from 'common/api/monday';

interface Result {
  short_code: string;
}

interface Res {
  result?: Result;
  success: boolean;
}

const Monday = () => {
  const {setNotificationList, user} = useAppContext();
  const [shortCode, setShortCode] = useState<string>();
  const {crmRequest} = useRequest();

  const getShortCode = async () => {
    const res: Res | undefined = await crmRequest({
      crm: user.crm,
      ...fetchShortCode(user.domain)
    } as any); // eslint-disable-line

    setShortCode(res?.result?.short_code || '');
  };

  useEffect(() => {
    getShortCode();
  }, []);

  const handleSubmit = async (values: PhoneCodeEditorValues) => {
    const phoneCode = values.phoneCode.trim();

    const res: Res | undefined = await crmRequest({
      crm: user.crm,
      ...putShortCode(user.domain, phoneCode)
    } as any); // eslint-disable-line

    if (res?.success)
      // eslint-disable-next-line
      setNotificationList((prevValue: any) =>
        update(prevValue, {
          $push: [
            {
              title: res.result?.short_code
                ? t`The phone code has been updated`
                : t`The phone code has been removed`
            }
          ]
        })
      );

    return;
  };

  return <PhoneCodeEditor onSubmit={handleSubmit} phoneCode={shortCode} />;
};

export default Monday;
