import React from 'react';
import {Formik, Form} from 'formik';
import update from 'immutability-helper';
import * as yup from 'yup';
import {t} from 'ttag';

import Background from '../../../../background/background.jsx';
import Text from '../../../../text/text.jsx';
import Link from '../../../../link/link.jsx';
import Wrapper from '../../../../wrapper/wrapper.jsx';
import Line from '../../../../line/line.jsx';
import {SaveButton} from '../../../../button/button';
import {useAppContext} from '../../../../../app-context.js';
import {useRequest} from '../../../../../common/hooks';
import {updateUserReq} from '../../../../../common/api/api.js';

import IsLink from './is-link/is-link.jsx';
import IsManager from './is-manager/is-manager.jsx';

const WhatcrmWeb = () => {
  const {user, setUser, setNotificationList} = useAppContext();

  const {request} = useRequest();

  const handleSubmit = async values => {
    const res = await request(updateUserReq(user.id, values));

    if (Object.keys(res).length) {
      setUser(prevValue =>
        update(prevValue, {
          is_link: {$set: res.is_link},
          is_manager: {$set: res.is_manager}
        })
      );

      setNotificationList(prevValue =>
        update(prevValue, {
          $push: [
            {
              title: user.domain,
              text: t`Your Whatcrm Web settings have been successfully updated.`
            }
          ]
        })
      );
    }
  };

  const initialValues = {
    is_link: user.is_link || 0,
    is_manager: user.is_manager || 0
  };

  const validationSchema = yup.object().shape({
    is_link: yup.number(),
    is_manager: yup.number()
  });

  return (
    <>
      <Background style={{marginBottom: 16}}>
        <Text color="dark">
          <Link href={process.env.REACT_APP_WEB} target="_blank">
            Whatcrm Web
          </Link>{' '}
          {t`is an application for messaging in WhatsApp and Telegram using multiple accounts.`}
        </Text>
      </Background>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Wrapper isColumn>
            <IsLink />
            <IsManager />
          </Wrapper>

          <Line />
          <SaveButton>{t`Save`}</SaveButton>
        </Form>
      </Formik>
    </>
  );
};

export default WhatcrmWeb;
