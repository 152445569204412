import React from 'react';
import {t} from 'ttag';
import * as AppEnv from 'app-env';
import Link from 'components/link/link';

const getFeatureList = (
  crm: AppEnv.Crm,
  packet_name: string,
  lang: string,
  isEnterpriseOption: boolean
) => {
  const limits = t`Sending and receiving files`;

  const checker = (
    packet_name == 'Enterprise' ? 10000 : packet_name == 'Pro' ? 4000 : 1000
  ).toLocaleString(lang);

  return [
    {
      name: limits,
      Start: true,
      Pro: true,
      Enterprise: true,
      isLimits: true
    },
    {
      name: t`WhatsApp automatic messaging`,
      Start: true,
      Pro: true,
      Enterprise: true,
      skip: crm != 'PIPEDRIVE',
      tooltip: (
        <>
          <span>{t`Set up automatic WhatsApp messages triggered by Pipedrive events, with the option to include attachments for enhanced client engagement.`}</span>
          {lang != 'ru' && (
            <>
              {` `}
              <Link
                href="https://whatcrm.gitbook.io/whatcrm-docs/v/english/integrations-products/pipedrive/automated-whatsapp-messaging-with-pipedrive-triggers"
                target="_blank"
              >
                {t`How to set up`}
              </Link>
              {`.`}
            </>
          )}
        </>
      )
    },
    {
      name: t`WhatsApp number checker`,
      skip: crm != 'PIPEDRIVE',
      Start: true,
      Pro: true,
      Enterprise: true,
      tooltip: (
        <>
          <span style={{display: 'block', marginBottom: 8}}>
            <span style={{fontWeight: 500}}>{checker}</span>
            {` `}
            {t`WhatsApp number checks per month.`}
          </span>

          <span style={{display: 'block', marginBottom: 8}}>
            {t`Easily verify if a contact’s phone number is registered on WhatsApp directly within the app. This feature is integrated into the contact card, organization, or deal view, showing a table with the number and registration status (Yes / No).`}
          </span>

          <span style={{display: 'block', marginBottom: 8}}>
            {t`This feature ensures you can quickly identify which of your contacts are reachable via WhatsApp, saving time and improving communication efficiency.`}
          </span>
        </>
      )
    },
    {
      name: t`Write first`,
      skip: crm === 'TELEGRAM' || crm === 'TELPHGRAM',
      Start: true,
      Pro: true,
      Enterprise: true
    },
    {
      name: t`Write first from any connection by phone number or username`,
      skip: crm !== 'TELEGRAM',
      Start: true,
      Pro: true,
      Enterprise: true
    },
    {
      name: t`Write first from any connection by phone number`,
      skip: crm !== 'TELPHGRAM',
      Start: true,
      Pro: true,
      Enterprise: true
    },
    {
      name: t`Working with Salesbot`,
      skip: crm !== 'AMO' && crm !== 'TELEGRAM',
      Start: true,
      Pro: true,
      Enterprise: true
    },
    {
      name: t`Working with chat bots and business Processes`,
      skip: crm !== 'B24' && crm !== 'TELPHGRAM',
      Start: true,
      Pro: true,
      Enterprise: true
    },
    {
      name: t`Unlimited dialogues`,
      Start: true,
      Pro: true,
      Enterprise: true
    },
    {
      name: t`Lead and deal creation`,
      Start: false,
      Pro: true,
      Enterprise: true,
      skip: crm != 'PIPEDRIVE',
      tooltip: (
        <>
          <span>{t`Automatically create leads or deals when a new client contacts you. This simplifies the process of managing inquiries, allows you to set up deal stages for deals, and automatically assigns responsible managers.`}</span>
          {lang != 'ru' && (
            <>
              {` `}
              {t`Learn`}
              {` `}
              <Link
                href="https://whatcrm.gitbook.io/whatcrm-docs/v/english/integrations-products/pipedrive/lead-and-deal-creation"
                target="_blank"
              >
                {t`how to configure`}
              </Link>
              {` `}
              {t`and use the new functionality.`}
            </>
          )}
        </>
      )
    },
    {
      name: t`CRM plugin for your website`,
      skip: ['MONDAY', 'PIPEDRIVE', 'TGMONDAY'].includes(crm),
      Start: true,
      Pro: true,
      Enterprise: true
    },
    {
      name: t`Auto messaging with files`,
      Start: true,
      Pro: true,
      Enterprise: true,
      skip: !['MONDAY', 'TGMONDAY'].includes(crm)
    },
    {
      name: 'Whatcrm Web',
      Start: crm === 'LK',
      Pro: true,
      Enterprise: true
    },
    {
      name: t`Choosing a pipeline for a phone number`,
      skip: crm !== 'TELEGRAM',
      Start: true,
      Pro: true,
      Enterprise: true
    },
    {
      name: t`Working with group chats`,
      Start: false,
      Pro: true,
      Enterprise: true
    },
    {
      name: t`Missed calls`,
      skip: crm === 'B24' || crm === 'TELPHGRAM',
      Start: false,
      Pro: true,
      Enterprise: true
    },
    {
      name: t`Sending and displaying reactions`,
      Start: false,
      Pro: true,
      Enterprise: true
    },
    {
      name: t`Salesbot with buttons`,
      // skip: crm !== 'AMO',
      skip: true,
      Start: false,
      Pro: true,
      Enterprise: true
    },
    {
      name: t`Distribution of incoming leads by strategies`,
      skip:
        crm != 'AMO' &&
        crm != 'TELEGRAM' &&
        crm != 'MONDAY' &&
        crm != 'TGMONDAY',
      Start: false,
      Pro: true,
      Enterprise: true
    },
    {
      name: t`Access to Whatcrm API`,
      skip: crm !== 'LK',
      Start: false,
      Pro: true,
      Enterprise: true
    },
    {
      name: t`Sending voice messages in open line chat`,
      skip: crm != 'B24',
      Start: false,
      Pro: false,
      Enterprise: true,
      isShown: true
    },
    {
      name: 'Поддержка по телефону',
      skip: !isEnterpriseOption || lang != 'ru',
      Start: false,
      Pro: false,
      Enterprise: true,
      isShown: true,
      tooltip: (
        <>
          {'С понедельника по пятницу'}
          <br />
          {'09:00–18:00'}
        </>
      )
    },
    {
      name: t`Private server`,
      skip: !isEnterpriseOption,
      Start: false,
      Pro: false,
      Enterprise: true,
      tooltip: t`A server whose resources are only under your control is allocated on request.`
    },
    {
      name: t`Connecting your own S3 storage`,
      skip: !isEnterpriseOption,
      Start: false,
      Pro: false,
      Enterprise: true,
      tooltip: t`Your own S3 storage can be used to store incoming and outgoing files.`
    },
    {
      name: t`Access to the history of all files`,
      skip: !isEnterpriseOption,
      Start: false,
      Pro: false,
      Enterprise: true
    }
  ].filter(item => !item.skip);
};

export default getFeatureList;
