import React, {useEffect} from 'react';
import update from 'immutability-helper';
import {t} from 'ttag';

import Entry from '../entry/entry.jsx';
import {useConnectionListContext} from '../../../../connection-list-context.js';
import {useConnectionContext} from '../../../connection-context.js';
import {useSettingsContext} from '../../settings-context.js';

const Amocrm = () => {
  const {connectionList, managerList, pipelineList, setConnectionList} =
    useConnectionListContext();
  const {connection} = useConnectionContext();
  const {connectionConfig} = useSettingsContext();

  const {manager_id, pipe_id, status_id} =
    connectionConfig.pipeline_settings?.[0] || {};

  const pipeline = pipelineList.find(item => item.id === pipe_id);
  const status = pipeline?.statuses.find(item => item.id === status_id);
  const manager = managerList.find(item => item[0] === manager_id);

  const connectionIndex = connectionList.findIndex(
    item => item.id === connection.id
  );

  useEffect(() => {
    setConnectionList(prevValue =>
      update(prevValue, {
        [connectionIndex]: {
          is_configured: {$set: !!pipeline}
        }
      })
    );
  }, [pipeline]);

  const managers = manager?.[1] ? (
    <span style={{display: 'flex'}}>
      <span className="one-line">{manager[1]}</span>

      {connectionConfig.pipeline_settings?.length > 1 && (
        <span>{` +${connectionConfig.pipeline_settings.length - 1}`}</span>
      )}
    </span>
  ) : undefined;

  return (
    <>
      <Entry
        label={t`Pipeline`}
        value={pipeline?.name || t`A selection is required`}
        color={!pipeline ? 'yellow' : undefined}
      />

      {!connection.is_limited && (
        <Entry label={t`Deal stage`} value={status?.name} />
      )}

      {!connection.is_limited && (
        <Entry
          label={t`Strategy`}
          value={
            connectionConfig.strategy === 'uniform'
              ? t`Uniform`
              : connectionConfig.strategy === 'random'
                ? t`Random`
                : undefined
          }
        />
      )}

      {!connection.is_limited && <Entry label={t`Managers`} value={managers} />}

      {!connection.is_limited && (
        <Entry
          label={t`Working with groups`}
          value={connectionConfig.group ? t`Yes` : t`No`}
        />
      )}

      <Entry
        label={t`Create deals if there are closed ones`}
        value={connectionConfig.closed ? t`Yes` : t`No`}
      />
    </>
  );
};

export default Amocrm;
