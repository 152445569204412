import React from 'react';
import {t} from 'ttag';

import Link from 'components/link/link';
import Icons from 'components/icons/icons';
import Text from 'components/text/text';
import Tooltip from 'components/tooltip/tooltip';
import Wrapper from 'components/wrapper/wrapper';
import {useConnectionContext} from '../../connection-context';
import {useCopyToClipboard} from 'common/hooks';

const WebhookUrl = () => {
  const {connection} = useConnectionContext();

  const copyToClipboard = useCopyToClipboard();
  const url = `https://pipedrive.whatcrm.net/automations/${connection.phone}/`;

  return (
    <Wrapper alignItems="center" gap={0}>
      <Link
        style={{display: 'flex', alignItems: 'center'}}
        isFake
        onClick={() => copyToClipboard(url)}
      >
        {t`Copy a Webhook URL`}
        <Icons.Copy style={{margin: '-8px 0'}} />
      </Link>

      <Tooltip style={{margin: '-8px 0'}}>
        <Text>
          {t`To set up`}
          {' '}
          <Link
            href="https://whatcrm.gitbook.io/whatcrm-docs/v/english/integrations-products/pipedrive/automated-whatsapp-messaging-with-pipedrive-triggers"
            target="_blank"
          >
            {t`message automation`}
          </Link>
          {` `}
          {t`in WhatsApp, you need to copy the following Webhook URL and paste it into your Pipedrive automation settings.`}
        </Text>
      </Tooltip>
    </Wrapper>
  );
};

export default WebhookUrl;
