import React, {useState, useRef, forwardRef} from 'react';
import cn from 'classnames';
import Eye from './eye/eye';
import Search from './search/search';

interface Input {
  autoComplete?: boolean;
  autoFocus?: boolean;
  inputMode?:
    | 'decimal'
    | 'email'
    | 'none'
    | 'numeric'
    | 'search'
    | 'tel'
    | 'text'
    | 'url';
  isCollapsible?: boolean;
  isMax?: boolean;
  isMin?: boolean;
  isSearch?: boolean;
  isValid?: boolean;
  maxLength?: number;
  name: string;
  onBlur?: (arg: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (arg: string) => void;
  placeholder?: string;
  style?: React.CSSProperties;
  type?: string;
  value: string;
}

const Input = forwardRef<HTMLInputElement, Input>(
  (
    {
      autoComplete = null,
      autoFocus,
      inputMode,
      isCollapsible = false,
      isMax,
      isMin,
      isSearch,
      isValid = true,
      maxLength,
      name,
      onBlur,
      onChange,
      placeholder,
      style,
      type = 'text',
      value
    },
    forwardRef
  ) => {
    const [htmlType, setHtmlType] = useState(type);
    const [isCollapsed, setIsCollapsed] = useState(isCollapsible);

    const innerRef = useRef<HTMLInputElement>(null);
    const ref = forwardRef || innerRef;

    const handleBlur: React.FocusEventHandler<HTMLInputElement> = e => {
      if (isCollapsible && !value) {
        setIsCollapsed(true);
      }

      if (onBlur) onBlur(e);
    };

    const htmlAutoComplete =
      typeof autoComplete === 'boolean' ? (autoComplete ? 'on' : 'off') : '';

    const htmlValue = value || '';

    const isPassword = type === 'password';
    const isWithIcon = isSearch || isPassword;

    return (
      <div
        className={cn('input', {
          input_collapsed: isCollapsed,
          input_collapsible: isCollapsible,
          input_max: isMax,
          input_min: isMin
        })}
        style={style}
      >
        <input
          className={cn('field input__field', {
            'input__field_with-icon': isWithIcon,
            field_error: !isValid
          })}
          inputMode={inputMode}
          maxLength={maxLength}
          name={name}
          placeholder={placeholder}
          ref={ref}
          type={htmlType}
          value={htmlValue}
          autoCapitalize={isSearch ? 'off' : 'on'}
          autoComplete={htmlAutoComplete}
          autoFocus={autoFocus}
          onBlur={handleBlur}
          onChange={e => onChange(e.target.value)}
        />

        {isPassword ? (
          <Eye inputRef={ref} htmlType={htmlType} setHtmlType={setHtmlType} />
        ) : (
          isSearch && (
            <Search
              inputRef={ref}
              isCollapsed={isCollapsed}
              setIsCollapsed={setIsCollapsed}
            />
          )
        )}
      </div>
    );
  }
);

Input.displayName = 'Input';
export default Input;
