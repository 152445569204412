import React, {useState} from 'react';
import {t} from 'ttag';
import update from 'immutability-helper';

import Advice from '../../../components/advice/advice';
import Button from '../../../components/button/button';
import EmptyBox from '../../../components/empty-box/empty-box.jsx';
import Grid from '../../../components/grid/grid.jsx';
import Icons from '../../../components/icons/icons.jsx';
import Link from '../../../components/link/link.jsx';
import Preloader from '../../../components/preloader/preloader.jsx';
import Section from '../../../components/section/section.jsx';
import Wrapper from '../../../components/wrapper/wrapper.jsx';
import {useAppContext} from '../../../app-context.js';
import {useHomeContext} from '../home-context.js';
import {useRequest} from '../../../common/hooks';
import {createMessageTemplateReq} from '../../../common/api/api.js';

import Editor from './editor/editor.jsx';
import MessageTemplate from './message-template/message-template.jsx';
import {useProcessAttachmentList} from './hooks.js';

const Automation = () => {
  const {lang, user} = useAppContext();
  const {messageTemplateList, setMessageTemplateList} = useHomeContext();

  const [isOpen, setIsOpen] = useState(false);

  const {request} = useRequest();
  const processAttachmentList = useProcessAttachmentList();

  const handleSubmit = async ({values, attachmentList}) => {
    const res = await request(createMessageTemplateReq(user.id, values));
    if (!Object.keys(res).length) return;

    setMessageTemplateList(prevValue =>
      update(prevValue, {
        $push: [{...res, file_count: attachmentList?.length || 0}]
      })
    );

    if (!attachmentList?.length) return;
    processAttachmentList({attachmentList, messageTemplateId: res.id});
  };

  let href =
    lang == 'ru'
      ? 'https://whatcrm.gitbook.io/whatcrm-docs/v/russian/produkty/whatcrm-web/shablony-dlya-whatcrm-web'
      : 'https://whatcrm.gitbook.io/whatcrm-docs/v/english/integrations-products/monday.com/template-creation-instruction';

  if (user.crm === 'PIPEDRIVE') {
    href = 'https://whatcrm.gitbook.io/whatcrm-docs/integrations-products/pipedrive/template-creation-instruction';
  }

  return (
    <>
      <Section title={t`Message templates`}>
        <Wrapper gap={16} style={{marginBottom: 16}} isColumn>
          <Advice>
            <Link href={href} target='_blank'>
              {t`Guidelines for creating templates`}
            </Link>
          </Advice>
        </Wrapper>

        <Button style={{marginBottom: 16}} onClick={() => setIsOpen(true)}>
          <Icons.Plus />
          {t`Create a message template`}
        </Button>

        {messageTemplateList ? (
          messageTemplateList.length ? (
            <Grid>
              {messageTemplateList.map(item => (
                <MessageTemplate key={item.id} messageTemplate={item} />
              ))}
            </Grid>
          ) : (
            <EmptyBox />
          )
        ) : (
          <Preloader />
        )}
      </Section>

      {isOpen && (
        <Editor
          headline={t`Create a message template`}
          onClose={() => setIsOpen(false)}
          onSubmit={handleSubmit}
        />
      )}
    </>
  );
};

export default Automation;
