import React from 'react';
import {Formik, Form} from 'formik';
import update from 'immutability-helper';
import * as yup from 'yup';
import {t} from 'ttag';

import Wrapper from '../../../../../wrapper/wrapper.jsx';
import Line from '../../../../../line/line.jsx';
import Button, {SaveButton} from '../../../../../button/button';
import Preloader from '../../../../../preloader/preloader.jsx';
import EmptyBox from '../../../../../empty-box/empty-box.jsx';
import {useAppContext} from '../../../../../../app-context.js';
import {useConnectionListContext} from '../../../../connection-list-context.js';
import {useConnectionContext} from '../../../connection-context.js';
import {useSettingsContext} from '../../settings-context.js';
import {useRequest} from '../../../../../../common/hooks';
import {updateConnectionConfigReq} from '../../../../../../common/api/amo.js';
import {trimValues} from '../../../../../../common/actions.js';

import PipelineSettings from './pipeline-settings/pipeline-settings.jsx';
import Groups from './group/group.jsx';
import Closed from './closed/closed.jsx';
import IfContact from './if-contact/if-contact.jsx';

const Amocrm = () => {
  const {setNotificationList} = useAppContext();
  const {client, setSettingsId} = useConnectionListContext();
  const {connection} = useConnectionContext();
  const {connectionConfig, updateConnectionConfig} = useSettingsContext();

  const {crmRequest} = useRequest();

  const handleSubmit = async values => {
    const trimmedValues = trimValues(values);

    const res = await crmRequest({
      crm: client.crm,
      ...updateConnectionConfigReq({
        domain: client.domain,
        phone: connection.phone,
        data: trimmedValues
      })
    });

    if (Object.keys(res).length > 3) {
      setNotificationList(prevValue =>
        update(prevValue, {
          $push: [
            {
              title: connection.label || `${t`Connection`} ${connection.id}`,
              text: t`The settings have been successfully saved.`
            }
          ]
        })
      );

      updateConnectionConfig(res);
      setSettingsId(-1);
    }

    return true;
  };

  const initialValues = connectionConfig
    ? {
        closed: connectionConfig.closed || false,
        group: connectionConfig.group || false,
        if_contact: connectionConfig.if_contact,
        pipeline_settings: connectionConfig.pipeline_settings || [],
        strategy: connectionConfig.strategy || ''
      }
    : {};

  const validationSchema = yup.object().shape({
    closed: yup.bool(),
    group: yup.bool(),
    if_contact: yup.bool(),
    pipeline_settings: yup.array(),
    strategy: yup.string()
  });

  return (
    <>
      {connectionConfig ? (
        Object.keys(connectionConfig).length ? (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            <Form>
              <Wrapper gap={16} isColumn>
                <PipelineSettings />

                <Wrapper isColumn>
                  <Groups />
                  <Closed />
                  <IfContact />
                </Wrapper>
              </Wrapper>

              <Line />

              <Wrapper>
                <SaveButton>{t`Save`}</SaveButton>

                <Button color="white" onClick={() => setSettingsId(-1)}>
                  {t`Cancel`}
                </Button>
              </Wrapper>
            </Form>
          </Formik>
        ) : (
          <EmptyBox />
        )
      ) : (
        <Preloader />
      )}
    </>
  );
};

export default Amocrm;
